import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Landing } from './components/layout/Landing';
import { Navbar } from './components/layout/Navbar';
import { Sidebar } from './components/layout/Sidebar';
import { Footer } from './components/layout/Footer';

const  App = () => {
  return (
    <Router>
      <Fragment>
        <Navbar/>
        <Sidebar/>
        <Route exact path='/' component={Landing}/>
        <Footer/>
      </Fragment>
    </Router>
    
  );
}

export default App;
