/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export const Landing = () => {
    return (
        <div id="main" className="site-main">
            <div className="layout-medium"> 
                <div id="primary" className="content-area">
                        <div id="content" className="site-content" role="main">
                            <article className="hentry page">
                                <div className="entry-content intro" data-animation="rotate-1">
                                    <div className="profile-image">
                                        <img alt="profile" src="images/site/about-me-3.jpg"/>
                                    </div>  
                                    <h2><em>Hi.</em> This is my Dev's Blog</h2>
                                    <h3>And you may find here&nbsp;
                                        <strong>GameDev</strong> 
                                        <strong>UE4/5</strong> 
                                        <strong>C++</strong> 
                                        <strong>Blender</strong> 
                                        <strong>Node.js</strong> 
                                        <strong>MERN</strong> 
                                        <strong>Rasp Pi</strong> 
                                        <strong>Eletronics</strong> 
                                        <strong>DevOps</strong> 
                                        <strong>3dPrinting</strong>                                         
                                    </h3>
                                    <figure>
                                        <a href="#"><img src="images/site/box-01.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">My Games</figcaption>
                                    </figure>        
                                    
                                    <figure>
                                        <a href="#"><img src="images/site/box-02.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 
                                    
                                    <figure>
                                        <a href="#"><img src="images/site/box-03.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 
                                    
                                    <figure>
                                        <a href="#"><img src="images/site/box-04.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 

                                    <figure>
                                        <a href="#"><img src="images/site/box-04.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 

                                    <figure>
                                        <a href="#"><img src="images/site/box-04.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 

                                    <figure>
                                        <a href="#"><img src="images/site/box-04.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 

                                    <figure>
                                        <a href="#"><img src="images/site/box-04.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 

                                    <figure>
                                        <a href="#"><img src="images/site/box-04.jpg" alt="About Me"/></a>
                                        <figcaption className="wp-caption-text">...</figcaption>
                                    </figure> 
                                </div> 
                            </article> 

                        <h3 className="widget-title home-title">LATEST POSTS</h3>  
                            
                        <div className="blog-simple">                                      
                            <article className="hentry post has-post-thumbnail">
                                <div className="hentry-left">
                                    <div className="entry-date">
                                        <span className="day">17</span>
                                        <span className="month">May</span>
                                        <span className="year">2015</span>
                                    </div>
                                    <div className="featured-image" style={{backgroundImage:"url('images/blog/06.jpg')"}}></div>
                                </div>
                                <div className="hentry-middle">
                                    <h2 className="entry-title"><a href="#">Sample post 1</a></h2>
                                </div>                                    
                                <a className="post-link" href="#">Sample post 1</a> 
                            </article>                                
                            <article className="hentry post has-post-thumbnail">
                                <div className="hentry-left">
                                    <div className="entry-date">
                                        <span className="day">03</span>
                                        <span className="month">Apr</span>
                                        <span className="year">2015</span>
                                    </div>
                                    <div className="featured-image" style={{backgroundImage:"url('images/blog/07.jpg')"}}></div>
                                </div>
                                <div className="hentry-middle">
                                    <h2 className="entry-title"><a href="#">Sample post 2</a></h2>
                                </div>                                    
                                <a className="post-link" href="#">Sample post 2</a>
                            </article>                                
                            <article className="hentry post has-post-thumbnail">
                                <div className="hentry-left">
                                    <div className="entry-date">
                                        <span className="day">09</span>
                                        <span className="month">Jan</span>
                                        <span className="year">2015</span>
                                    </div>
                                    <div className="featured-image" style={{backgroundImage:"url('images/blog/06.jpg')"}}></div>
                                </div>                                    
                                <div className="hentry-middle">
                                    <h2 className="entry-title"><a href="#">Sample post 3</a></h2>
                                </div>                                    
                                <a className="post-link" href="#">Sample post 3</a>
                            </article>                                
                            <article className="hentry post has-post-thumbnail">
                                <div className="hentry-left">
                                    <div className="entry-date">
                                        <span className="day">14</span>
                                        <span className="month">Jun</span>
                                        <span className="year">2015</span>
                                    </div>
                                    <div className="featured-image" style={{backgroundImage:"url('images/blog/06.jpg')"}}></div>
                                </div>
                                <div className="hentry-middle">
                                    <h2 className="entry-title"><a href="#">Sample post 4</a></h2>
                                </div>
                                <a className="post-link" href="#">Sample post 4</a>
                            </article>                                
                            <article className="hentry post has-post-thumbnail">
                                <div className="hentry-left">
                                    <div className="entry-date">
                                        <span className="day">25</span>
                                        <span className="month">Agu</span>
                                        <span className="year">2015</span>
                                    </div>
                                    <div className="featured-image" style={{backgroundImage:"url('images/blog/06.jpg')"}}></div>
                                </div>                                    
                                <div className="hentry-middle">
                                    <h2 className="entry-title"><a href="#">Sample post 5</a></h2>
                                </div>                                    
                                <a className="post-link" href="#">Sample post 5</a>
                            </article>
                        </div>                         
                        <div className="home-launch">
                            <a className="button" href="#">See All Posts</a>
                        </div> 
                    </div>
                </div>                
            </div>            
        </div>
    )
}
