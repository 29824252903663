
import React from 'react'

export const Sidebar = () => {
    return (  
        <div id="sidenav" className="sidenav">
            <div className="nav-menu">
                <ul>
                    {/* Log in / register */}
                    <li>
                        <a className="login" href="/" >
                            <i className="pw-icon-gplus"/>&nbsp;&nbsp;
                            Login / Register
                        </a> 
                    </li> 

                    {/* Logged In */}
                    {/*<li>
                        <img className="avatar" alt="" src="images/site/testo-01.jpg" height="40" width="40"></img>                        
                        <a href="/" classname="user">Bruno Colli Boat</a> 
                        <ul>
                            <li><a href="/"><i className="pw-icon-export"/> Logout</a></li>
                        </ul>
                    </li> */}
                </ul>
            </div>
        </div> 
      )
}