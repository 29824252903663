/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

export const Footer = () => {
    return (
        <footer id="colophon" className="site-footer" role="contentinfo">
            <div className="layout-medium">
                <div className="site-title-wrap">
                    <h1 className="site-title">
                    <a href="index.html" rel="home">
                            <img src="images/site/logo.png" alt="logo"/>
                        </a>
                    </h1>
                    <p className="site-description">Tech Blog and Scrapyard.</p>
                </div>
                <div className="footer-social">
                    <div className="textwidget">

                        <a className="social-link facebook" href="#"></a> &nbsp;
                        <a className="social-link twitter" href="#"></a> &nbsp;
                        <a className="social-link youtube" href="#"></a>&nbsp;
                        <a className="social-link linkedin" href="#"></a>&nbsp;
                        <a className="social-link instagram" href="#"></a>

                    </div>
                </div>                          
            </div>
            <div className="site-info">
            	<div className="layout-medium">
            		<div className="textwidget">layout by Pixelwars</div>
                </div>
            </div>
		</footer>        
    )
}
