/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export const Navbar = () => {
    return ( 
        <header id="masthead" className="site-header" role="banner">
                <nav id="primary-navigation" className="site-navigation primary-navigation" role="navigation">
                    <div className="layout-medium">
                        <h1 className="site-title">
                            <a href="/" rel="home">
                                <img src="../images/site/logo.png" alt="logo"/>
                                <span className="screen-reader-text">Bruno Colli</span>
                            </a>
                        </h1>
                        <a className="menu-toggle"><span className="lines"></span></a>
                        <div className="nav-menu">
                            <ul> 
                                <li><a href="/">HOME</a></li>&nbsp;
                                <li><a href="mygames.html">GAMES</a></li>&nbsp;
                                <li><a href="posts.html">POSTS</a>&nbsp;
                                    <ul>
                                        <li><a href="ue4.html">Game Dev Series</a> 
                                            <ul>
                                                <li><a href="rts_from_scratch.html">RTS from Scratch</a></li> 
                                            </ul>                                         
                                        </li>
                                        <li><a href="unreal_engine.html">Unreal Engine</a>
                                            <ul>
                                                <li><a href="bp_snippets.html">BP Snippets</a></li>                                                 
                                                <li><a href="c_snippets.html">C++ Snippets</a></li> 
                                            </ul> 
                                        </li>
                                        <li><a href="blender.html">Blender</a></li>
                                        <li><a href="nodejs.html">Node.js</a></li> 
                                        <li><a href="devops.html">DevOps</a></li> 
                                    </ul>                                
                                </li>                          
                                <li><a href="about.html">ABOUT ME</a></li>&nbsp;
                                <li><a href="contact.html">CONTACT</a></li>
                            </ul>
                        </div>                    
                        
                        <div className="social-container">
                            <a className="social-link facebook" href="link.html"></a>&nbsp;
                            <a className="social-link twitter" href="link.html"></a>&nbsp;
                            <a className="social-link youtube" href="link.html"></a>&nbsp;
                            <a className="social-link linkedin" href="link.html"></a>&nbsp;
                            <a className="social-link instagram" href="link.html"></a>                                                   
                        </div>  

                        <a className="search-toggle toggle-link"></a>&nbsp;
                        <div className="search-container">
                            <div className="search-box" role="search">
                                <form method="get" className="search-form" action="#">
                                    <label>Search Here
                                        <input type="search" id="search-field" placeholder="type and hit enter" name="s"/>
                                    </label>
                                    <input type="submit" className="search-submit" value="Search"/> 
                                </form>
                            </div>
                        </div> 
                    </div>                
                </nav>
        </header>
    )
}
